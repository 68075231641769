// @ts-strict-ignore
import { GetMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { ApiOrderType, ApiTimeInForce } from 'phoenix/models/ApiTradeRequest';
import { TradeableSecurityType } from '../redux/models';
import { IsBetaMaintenanceTime, IsWeekend, MarketTimeSegment } from '../util/DateFormatting';

export type EditCancelRule = 'allowed' | 'not-allowed' | 'contact';
export type AlgoStrat = 'Care' | 'VWAP' | 'TWAP' | 'POV' | 'SOR';
export type AlwaysNeverGtx = 'always' | 'never' | 'gtx-only';
export type MarketTimeSegmentDefinition = {
    timesInForce?: ApiTimeInForce[];
    /** useful if overall edit is `not-allowed` */
    editableTimesInForce?: ApiTimeInForce[]; // Orders with this Time In Force can be edited
    canChangeTimeInForce?: {
        initialOrderType?: ApiOrderType[]; // User can change TIF while placing an initial order of types within this array, if it exists
        modifyingOrder?: ApiTimeInForce[]; // Existing order modifications can only change TIF if current TIF is within this array, if it exists
    };
    strategies?: AlgoStrat[];
    orderTypes?: { orderType: ApiOrderType; timesInForce?: ApiTimeInForce[] }[];
    edit?: EditCancelRule;
    cancel?: EditCancelRule;
    editNotAllowedMessage?: string;
    cancelNotAllowedMessage?: string;
    disabled?: boolean;
};
export type EditableTradeField = 'quantity' | 'action' | 'time-in-force' | 'order-type' | 'prices' | 'algo-strat';
const StandardEditableFields = new Set<EditableTradeField>(['quantity', 'time-in-force', 'order-type', 'prices', 'algo-strat']);

export interface AssetTradeabilityProfile {
    enabled: boolean;
    requiresOptionPermission: boolean;
    requiresFuturesAccount?: boolean;
    requiresEquitiesAccount?: boolean;
    nbboRequired: boolean;
    accountMustBeUnrestricted: boolean;
    hasCustomSessionWindow: boolean;
    permittedOrderTypes: ApiOrderType[];
    hasExtendedHours: boolean;
    /**
     * @deprecated please use the new `quantityQualifiersByAction` property that specifies the allowed qualifiers for each individual action
     */
    quantityQualifiers?: ('Shares' | 'EvenDollar')[];

    quantityQualifiersByAction?: {
        buy: ('Shares' | 'EvenDollar')[];
        sell: ('Shares' | 'EvenDollar')[];
    };

    permitsAdvancedRouting?: boolean;
    timesInForce: ApiTimeInForce[];
    canTradeOptions?: boolean;
    permittedAlgos?: AlgoStrat[];
    optionsRequireOptionLevel?: boolean;
    canPartialFill?: boolean;
    canTradeOnClose?: boolean;
    editableFields: Set<EditableTradeField>;
    editRule?: EditCancelRule;
    cancelRule?: EditCancelRule;
    editNotAllowedMessage?: string;
    cancelNotAllowedMessage?: string;

    schedule: Partial<{
        betaMaintenance: MarketTimeSegmentDefinition;
        premarket: MarketTimeSegmentDefinition;
        marketOpen: MarketTimeSegmentDefinition;
        postmarket: MarketTimeSegmentDefinition;
        marketClosed: MarketTimeSegmentDefinition;
        holidays: MarketTimeSegmentDefinition;
        weekends: MarketTimeSegmentDefinition;
    }>;
    /* used to show pretrade disclosures. currently only used by cryptos. */
    /* if this value is set, there needs to be text added to Snex1LanguagePack.warnings.pretradeDisclosures.[assetFamily] */
    showPreTradeDisclosure?: boolean;
}

const equityProfile: AssetTradeabilityProfile = {
    enabled: true,
    requiresOptionPermission: false,
    requiresEquitiesAccount: true,
    nbboRequired: true,
    accountMustBeUnrestricted: true,
    hasExtendedHours: true,
    permittedOrderTypes: ['market', 'limit', 'stop', 'stoplimit'],
    hasCustomSessionWindow: false,
    quantityQualifiers: ['Shares'],
    quantityQualifiersByAction: {
        buy: ['Shares'],
        sell: ['Shares']
    },
    canTradeOptions: true,
    optionsRequireOptionLevel: true,
    canPartialFill: true,
    canTradeOnClose: true,
    editableFields: StandardEditableFields,
    editRule: 'allowed',
    cancelRule: 'allowed',

    permitsAdvancedRouting: true,
    permittedAlgos: ['Care', 'TWAP', 'VWAP', 'SOR', 'POV'],

    timesInForce: ['Day', 'GTC', 'GTXPost', 'NTE', 'GTXPre'],
    schedule: {
        betaMaintenance: {
            cancel: 'not-allowed',
            disabled: true,
            edit: 'not-allowed'
        },
        premarket: {
            cancel: 'allowed',
            timesInForce: ['Day', 'GTC', 'GTXPre'],
            // strategies: ['Care', 'TWAP', 'VWAP', 'SOR'],
            orderTypes: [
                { orderType: 'limit' },
                { orderType: 'market', timesInForce: ['Day', 'GTC'] },
                { orderType: 'stoplimit', timesInForce: ['Day', 'GTC'] },
                { orderType: 'stop', timesInForce: ['Day', 'GTC'] }
            ],
            edit: 'allowed'
        },
        marketOpen: {
            cancel: 'allowed',
            canChangeTimeInForce: {
                modifyingOrder: ['Day', 'GTC'] // Cannot change TIF on GTX orders
            },
            strategies: ['Care', 'TWAP', 'VWAP', 'SOR', 'POV'],
            timesInForce: ['Day', 'GTC'],
            edit: 'allowed'
        },
        postmarket: {
            cancel: 'allowed',
            canChangeTimeInForce: {
                initialOrderType: ['limit'] // Stop and stop limit orders can only be GTC at this time
            },
            timesInForce: ['GTC', 'GTXPost', 'NTE'],
            editableTimesInForce: ['GTC', 'GTXPost', 'NTE'],
            // strategies: ['Care', 'TWAP', 'VWAP', 'SOR'],
            orderTypes: [{ orderType: 'limit' }, { orderType: 'stoplimit', timesInForce: ['GTC'] }, { orderType: 'stop', timesInForce: ['GTC'] }],
            edit: 'not-allowed'
        },
        marketClosed: {
            cancel: 'allowed',
            timesInForce: ['Day', 'GTC'],
            edit: 'not-allowed'
        },
        weekends: {
            cancel: 'allowed',
            timesInForce: ['Day', 'GTC'],
            edit: 'not-allowed'
        },
        holidays: {
            cancel: 'allowed',
            timesInForce: ['Day', 'GTC'],
            edit: 'not-allowed'
        }
    }
};

// Can always trade everything, but can never change TIF while modifying an order
const cannotModifyTimeInForceSchedule = {
    betaMaintenance: {
        canChangeTimeInForce: {
            modifyingOrder: []
        }
    },
    premarket: {
        canChangeTimeInForce: {
            modifyingOrder: []
        }
    },
    marketOpen: {
        canChangeTimeInForce: {
            modifyingOrder: []
        }
    },
    postmarket: {
        canChangeTimeInForce: {
            modifyingOrder: []
        }
    },
    marketClosed: {
        canChangeTimeInForce: {
            modifyingOrder: []
        }
    },
    weekends: {
        canChangeTimeInForce: {
            modifyingOrder: []
        }
    },
    holidays: {
        canChangeTimeInForce: {
            modifyingOrder: []
        }
    }
};

const neverTradeable: AssetTradeabilityProfile = {
    enabled: false,
    requiresOptionPermission: false,
    requiresEquitiesAccount: false,
    nbboRequired: false,
    accountMustBeUnrestricted: true,
    hasCustomSessionWindow: false,
    permittedOrderTypes: [],
    timesInForce: [],
    quantityQualifiers: [],
    quantityQualifiersByAction: {
        buy: [],
        sell: []
    },
    hasExtendedHours: false,
    editableFields: new Set([]),
    editRule: 'not-allowed',
    cancelRule: 'not-allowed',

    schedule: {
        betaMaintenance: { disabled: true },
        premarket: { disabled: true },
        marketOpen: { disabled: true },
        postmarket: { disabled: true },
        marketClosed: { disabled: true },
        weekends: { disabled: true },
        holidays: { disabled: true }
    }
};

const futuresProfile: AssetTradeabilityProfile = {
    accountMustBeUnrestricted: true,
    canTradeOptions: true,
    editableFields: new Set<EditableTradeField>(['quantity', 'prices']),
    enabled: true,
    editRule: 'allowed',
    cancelRule: 'allowed',
    hasCustomSessionWindow: false,
    hasExtendedHours: false,
    nbboRequired: true,
    permittedOrderTypes: ['market', 'limit', 'stop', 'stoplimit'],
    quantityQualifiers: ['Shares'],
    quantityQualifiersByAction: {
        buy: ['Shares'],
        sell: ['Shares']
    },
    requiresFuturesAccount: true,
    requiresOptionPermission: false,
    schedule: cannotModifyTimeInForceSchedule,
    timesInForce: ['Day', 'GTC']
};

export const AssetTradeability: Record<TradeableSecurityType | 'loading', AssetTradeabilityProfile> = {
    equity: equityProfile,
    etf: equityProfile,
    adr: equityProfile,
    option: {
        // Equity Options
        ...equityProfile,
        canTradeOnClose: false,
        hasExtendedHours: false,
        requiresOptionPermission: true,
        permitsAdvancedRouting: true,
        canTradeOptions: false,
        permittedAlgos: ['Care', 'SOR'],
        timesInForce: ['Day', 'GTC'],
        permittedOrderTypes: ['market', 'limit', 'stop', 'stoplimit'],
        schedule: {
            ...equityProfile.schedule,
            premarket: {
                timesInForce: ['Day', 'GTC'],
                edit: 'allowed'
            },
            postmarket: {
                canChangeTimeInForce: {
                    modifyingOrder: []
                },
                timesInForce: ['GTC'],
                editableTimesInForce: ['GTC'],
                edit: 'not-allowed'
            }
        }
    },

    future: futuresProfile,
    'futures-option': {
        enabled: true,
        accountMustBeUnrestricted: true,
        hasCustomSessionWindow: false,
        nbboRequired: true,
        schedule: cannotModifyTimeInForceSchedule,
        timesInForce: ['Day', 'GTC'],
        requiresFuturesAccount: true,
        hasExtendedHours: false,
        requiresOptionPermission: false,
        permitsAdvancedRouting: false,
        canTradeOptions: false,
        permittedAlgos: undefined,
        permittedOrderTypes: ['market', 'limit', 'stoplimit'],
        editableFields: new Set<EditableTradeField>(['quantity', 'prices']),
        editRule: 'allowed',
        cancelRule: 'allowed'
    },
    'futures-time-spread': futuresProfile,
    crypto: {
        enabled: false,
        requiresOptionPermission: false,
        nbboRequired: true,
        accountMustBeUnrestricted: true,
        hasCustomSessionWindow: false,
        permittedOrderTypes: ['market', 'limit', 'stop', 'stoplimit'],
        quantityQualifiers: ['Shares', 'EvenDollar'],
        quantityQualifiersByAction: {
            buy: ['Shares', 'EvenDollar'],
            sell: ['Shares', 'EvenDollar']
        },
        hasExtendedHours: false,
        canTradeOptions: false,
        editableFields: StandardEditableFields,
        editRule: 'allowed',
        cancelRule: 'allowed',

        timesInForce: ['FOK', 'GTC'],
        schedule: {}, // Always tradeable
        showPreTradeDisclosure: true
    },

    'mutual-fund': {
        enabled: true,
        requiresOptionPermission: false,
        nbboRequired: false,
        accountMustBeUnrestricted: false,
        hasCustomSessionWindow: false,
        permittedOrderTypes: ['market'],
        quantityQualifiers: ['Shares', 'EvenDollar'],
        quantityQualifiersByAction: {
            buy: ['Shares', 'EvenDollar'],
            sell: ['Shares', 'EvenDollar']
        },
        hasExtendedHours: false,
        canTradeOptions: false,
        editableFields: new Set([]),
        editRule: 'allowed',
        cancelRule: 'allowed',
        timesInForce: ['Day'],
        schedule: {} // Always tradeable
    },

    'offshore-mutual-fund': {
        enabled: true,
        requiresOptionPermission: false,
        nbboRequired: false,
        accountMustBeUnrestricted: false,
        hasCustomSessionWindow: false,
        permittedOrderTypes: ['market'],
        quantityQualifiers: ['Shares', 'EvenDollar'],
        quantityQualifiersByAction: {
            buy: ['EvenDollar'],
            sell: ['Shares', 'EvenDollar']
        },
        hasExtendedHours: false,
        canTradeOptions: false,
        editableFields: new Set([]),
        editRule: 'contact',
        cancelRule: 'contact',
        timesInForce: ['Day'],
        schedule: {} // Always tradeable
    },

    unknown: neverTradeable,
    loading: neverTradeable
};

export const CanTradeOrderTypeNow = (profile: AssetTradeabilityProfile, marketSegment: MarketTimeSegment, orderType: ApiOrderType): boolean => {
    const { schedule } = profile;

    if (!schedule || !Object.entries(schedule)?.length) return true;
    let currentSchedule: MarketTimeSegmentDefinition = {};
    switch (marketSegment) {
        case 'closed':
            currentSchedule = schedule.marketClosed;
            break;
        case 'holiday':
            currentSchedule = schedule.holidays;
            break;
        case 'open':
            currentSchedule = schedule.marketOpen;
            break;
        case 'postmarket':
            currentSchedule = schedule.postmarket;
            break;
        case 'premarket':
            currentSchedule = schedule.premarket;
            break;
        default:
            currentSchedule = {};
            break;
    }

    if (!currentSchedule?.orderTypes?.length && !currentSchedule?.disabled) return true;

    const isOrderTypeAllowedNow = currentSchedule?.orderTypes?.find((o) => o.orderType === orderType);

    if (isOrderTypeAllowedNow && !currentSchedule?.disabled) return true;

    return false;
};

export const GetTradeabilityTimeSegmentDefinition = (profile: AssetTradeabilityProfile): MarketTimeSegmentDefinition => {
    if (IsBetaMaintenanceTime()) return profile.schedule.betaMaintenance;
    if (IsWeekend()) return profile.schedule.weekends;

    const time = GetMarketTimeSegmentV2();
    // prettier-ignore
    switch (time) {
        case 'closed': return profile.schedule.marketClosed
        case 'premarket': return profile.schedule.premarket;
        case 'open': return profile.schedule.marketOpen;
        case 'postmarket': return profile.schedule.postmarket;
        case 'holiday': return profile.schedule.holidays;
        case 'loading': return null;
    }
};

export const TifEnabledAtTime = (tif: ApiTimeInForce, time: MarketTimeSegment, orderType: ApiOrderType, tProfile: AssetTradeabilityProfile): boolean => {
    const def = GetTradeabilityTimeSegmentDefinition(tProfile);
    if (!def?.timesInForce) return true; // Lack of schedule / TIF list implies that there are no restrictions
    const allowedByTifWhitelist = def?.timesInForce?.includes(tif) || false;
    const allowedForSelectedOrderType = (() => {
        if (!def?.orderTypes?.length) return true;
        const relatedOrderType = def?.orderTypes.find((o) => o.orderType === orderType);
        if (!relatedOrderType) return false;
        if (!relatedOrderType?.timesInForce?.length || relatedOrderType?.timesInForce.includes(tif)) return true;
        return false;
    })();
    return (allowedByTifWhitelist && allowedForSelectedOrderType) || false;
};

/**
 * Function to get the edit rule for a given profile & order if provided.
 *
 * @param {AssetTradeabilityProfile} tProfile - The tradeability profile of the asset.
 * @param {ApiTradeRequest} [trade] - Optional parameter for the trade request, containing details such as time-in-force.
 * @returns {EditCancelRule} The edit rule for the given profile and order.
 */

// 2nd argument initially implemented as ApiTradeRequest so this is destructured to be backwards compatible
// TODO: Mobile can pass in TIF instead of entire trade object
export const GetEditRule = (tProfile: AssetTradeabilityProfile, { timeInForce }: { timeInForce?: ApiTimeInForce } = {}): EditCancelRule => {
    // Get the current market time segment definition based on the profile.
    const schedule = GetTradeabilityTimeSegmentDefinition(tProfile);

    // 1. If the profile's main edit rule is not 'allowed', return it.
    if (tProfile.editRule !== 'allowed') {
        return tProfile.editRule;
    }

    // 2. If a schedule exists, check for specific rules within the schedule.
    if (schedule) {
        // 3, Check if the schedule has editable times-in-force and if the order's time-in-force is included.
        if (schedule?.editableTimesInForce?.length) {
            if (schedule?.editableTimesInForce.includes(timeInForce)) return 'allowed';
        }

        // Otherwise, f the schedule has an edit rule, return it.
        if (schedule.edit) {
            return schedule.edit;
        }
    }

    // If no specific rules are found, return the profile's main edit rule, defaulting to 'allowed' if undefined.
    return tProfile.editRule ?? 'allowed';
};

// Function to get the cancel rule for a given profile
export const GetCancelRule = (tProfile: AssetTradeabilityProfile): EditCancelRule => {
    const schedule = GetTradeabilityTimeSegmentDefinition(tProfile);
    if (tProfile.cancelRule !== 'allowed') {
        return tProfile.cancelRule;
    }

    if (schedule) {
        if (schedule.cancel) {
            return schedule.cancel;
        }
    }

    return tProfile.cancelRule ?? 'allowed';
};

// Whether placing a new order or modifying existing order, determine whether the TIF can be changed
// TODO GetTradeabilityTimeSegmentDefinition relies on machine time so it is untestable - abstract the time value to an argument
export const GetCanChangeTimeInForce = ({
    modifyingOrder,
    orderType,
    timeInForce,
    tProfile
}: {
    modifyingOrder?: boolean;
    orderType?: ApiOrderType;
    timeInForce?: ApiTimeInForce;
    tProfile: AssetTradeabilityProfile;
}): boolean => {
    const schedule = GetTradeabilityTimeSegmentDefinition(tProfile);

    // If this property doesn't exist, assume all TIFs can be changed in all cases
    if (!schedule?.canChangeTimeInForce) return true;

    if (modifyingOrder) {
        // If this property doesn't exist, assume all TIFs can be changed while modifying orders
        // (Note that an empty array means no TIF can be changed during this segment)
        if (!schedule.canChangeTimeInForce?.modifyingOrder) return true;

        return !!timeInForce && schedule.canChangeTimeInForce.modifyingOrder.includes(timeInForce);
    }

    // If this property doesn't exist, assume all TIFs can be changed for all initial order types
    return !schedule.canChangeTimeInForce.initialOrderType || (!!orderType && schedule.canChangeTimeInForce.initialOrderType.includes(orderType));
};
