// @ts-strict-ignore
import { AspectRatio, MoreVert, Notifications } from '@mui/icons-material';
import { Checkbox, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { getCandleScrubValues, getLineScrubValues, getMultiSeriesConfig } from 'components/Charting/Chart/helpers';
import MultiSeriesChart, { CrosshairUpdateValueWithChange } from 'components/Charting/Chart/MultiSeriesChart';
import { getVisibleLogicalRangeOffset } from 'components/Charting/SecurityChartWrapper/Helpers';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { SnexPopover } from 'components/Popovers/SnexPopover';
import { SecurityValues } from 'components/SecurityValues/SecurityValues';
import {
    setSecurityValues,
    setSecurityValuesOverride,
    setShowAdditionalStats,
    setStartingValue,
    useShowAdditionalStats
} from 'components/SecurityValues/SecurityValuesViewModel';
import { ShiningWrapper } from 'components/ShiningWrapper';
import VolumeBadge from 'components/VolumeBadge';
import { getUnixTime } from 'date-fns';
import { useColors } from 'hooks/UseColors';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { useAppWindowSize, useAppWindowSizeVariable } from 'hooks/UseWindowSize';
import { isUTCTimestamp } from 'lightweight-charts';
import { StorageKeys } from 'phoenix/constants';
import { ChartMinuteOffsetsByRange, ChartRange, ChartRanges } from 'phoenix/constants/ChartRange';
import { SnexChartPoint, SnexChartTimezone, SnexChartTimezoneType } from 'phoenix/constants/ChartTypes';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { useIsDelayedPricing } from 'phoenix/hooks/UseIsDelayedPricing';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { useTier } from 'phoenix/hooks/UseTier';
import { MutualFundAssetClass } from 'phoenix/models/AssetClasses/MutualFundAssetClass';
import { useAssetClass, useAssetClassColors } from 'phoenix/models/AssetClasses/useAssetClass';
import { GetSecurityCompanyInfoAction, GetSecurityMetadataAction, GetUpcomingEarningsAction } from 'phoenix/redux/actions';
import { GetSecuritySymbolChart } from 'phoenix/redux/actions/SecurityChartActions';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions/SecurityQuoteActions';
import { SecurityChartData } from 'phoenix/redux/models';
import { FormatNumber, IsMarketOpen, MarketTimeSegment, QuoteAttribute, UniqueBy } from 'phoenix/util';
import { GetVariant } from 'phoenix/util/Variant';
import { XstreamUpdateChartFrequencyInMinutesAction } from 'phoenix/xstream/actions/XstreamChartActions';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { createContext, Dispatch, ReactChild, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSecurityChartStore } from 'store/SecurityChart';
import { GetDeviceThemeVariant } from 'theming/GetDeviceThemeVariant';
import { Routes } from 'util/Routes';
import { shallow } from 'zustand/shallow';
import { ActionModal, Card, Flex } from '..';
import { TelemetryCategories } from '../../constants/Telemetry/TelemetryCategories';
import { TelemetryProvider } from '../../providers/TelemetryContext';
import { ArrowLink } from '../ArrowLink/ArrowLink';
import { CandleLineToggleControls } from '../CandleLineToggleControls/CandleLineToggleControls';
import { SkeletonChart } from '../Charting/Chart/SkeletonChart';
import { RangeSelector } from '../Charting/RangeSelectors';
import { CircularLogo } from '../CircularLogo/CircularLogo';
import { LastQuoteUpdateDisplay } from '../LastQuoteUpdateDisplay/LastQuoteUpdateDisplay';
import { AlertsModal } from '../Modals';
import { AddToListModal } from '../Modals/AddToListModal';
import { AddToListIcon } from './AddToListIcon';
import EarningsDateTooltip from './EarningsDateTooltip';
import { ExtendedHoursSnapshot } from './ExtendedHoursSnapshot';
import './style.scss';

interface SecurityChartSectionProps {
    accentColor?: string;
    accentColorLighter?: string;
    defaultRange?: ChartRange;
    header?: JSX.Element;
    hiddenRanges?: Set<string>;
    hideAdvanced?: boolean;
    hideAlerts?: boolean;
    hideCandlestick?: boolean;
    hideExtendedHours?: boolean;
    hideChangeBar?: boolean;
    showAllData?: boolean;
    symbol?: string;
    showSkeleton?: boolean;
}

export type ohlcvBar = { close: number; open: number; high: number; low: number; value?: number; volume: number };

const GoldWrapper = ({ children, wrap }: { children: ReactChild; wrap?: boolean }) => (wrap ? <ShiningWrapper>{children}</ShiningWrapper> : <>{children}</>);

// Orders can only determined to be visible on the chart by using the imperative lightweight-charts API
// This context will allow the working orders component to inform the HoC whether or not to show the working orders toggle
export interface ISecurityChartContext {
    workingOrdersInRange?: boolean;
    setWorkingOrdersInRange: Dispatch<SetStateAction<boolean>>;
}

export const SecurityChartContext = createContext<ISecurityChartContext>(undefined);
export const SecurityChartSection = TelemetryProvider((props: SecurityChartSectionProps) => {
    const {
        accentColor,
        accentColorLighter,
        defaultRange = '1d',
        header,
        hideCandlestick,
        hiddenRanges,
        hideAdvanced,
        hideChangeBar,
        hideAlerts,
        hideExtendedHours,
        showAllData,
        symbol,
        showSkeleton = false
    } = props;
    const dispatch = useDispatch();
    const xdispatch = useXstreamDispatch();
    const mountedRef = useRef<boolean>(false);
    const [toggleMenuOpen, setToggleMenuOpen] = useState<boolean>(false);
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
    const [range, setRange] = useState<ChartRange>(defaultRange);
    const [isLoading, setIsLoading] = useState(true);
    const appWindowSize = useAppWindowSize()[0];
    const [streamingChartData, setStreamingChartData] = useState<SecurityChartData[]>([]);
    const [addToListOpen, setAddToListOpen] = useState(false);
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const [prevMarketHours, setPrevMarketHours] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [, setIsScrubbing] = useState(false);
    const allText = useText((t) => t);
    const text = allText.securityScreen;
    const chartAllRanges = useSnexStore((state) => state.securityChart.bySymbol[symbol]);
    const chart = chartAllRanges?.[range];
    const xStreamQuote: StandardQuote = XS.Quotes.use(symbol);
    const apiQuote = useSnexStore((s) => s.securityQuote.bySymbol[symbol]);
    const streamingData: SecurityChartData[] = XS.SecurityCharts.use(symbol);
    const companyInfo = useSnexStore((state) => state.securities.bySymbol[symbol]?.companyInfo);
    const metadata = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata);
    const upcomingEarnings = useSnexStore((s) => s.securities.bySymbol[symbol]?.upcomingEarnings);
    const earningsDate = upcomingEarnings?.data?.[0]?.reportDate;
    const assetClass = useAssetClass(symbol);
    const advancedChartText = assetClass.chartVariants.includes('dom') ? text.advancedDomAndChart : text.advancedChart;
    const { formatPrice } = assetClass;
    const { product } = useTier();
    const rangeIsOneDay = ['1d', '24h'].includes(range);
    const colors = useColors();
    const [chartData, setChartData] = useState<SecurityChartData[]>([]);
    const [showWorkingOrders, setShowWorkingOrders] = useLocalStorage<boolean>(StorageKeys.BigChartShowWorkingOrders, true);
    const [showVolume, setShowVolume] = useLocalStorage<boolean>(StorageKeys.BigChartShowVolume, true);
    const [workingOrdersInRange, setWorkingOrdersInRange] = useState<boolean>(false);
    const showWorkingOrdersCheckbox = workingOrdersInRange;

    const delayedData = useIsDelayedPricing(symbol);

    const handleToggleMenu = () => setToggleMenuOpen(!toggleMenuOpen);

    const showAdditionalStats = useShowAdditionalStats();

    const formatValue = useCallback((value: number) => formatPrice(value, metadata?.data), [formatPrice, metadata?.data]);

    const excludeChartRanges = () => {
        const assetChartRanges = assetClass.chartRanges().map((range) => range.value);
        return new Set(
            Object.values(ChartRanges)
                .filter((range) => !assetChartRanges.includes(range))
                .map((range) => range as string)
        );
    };

    const { seriesType, setChartValues } = useSecurityChartStore(
        (s) => ({
            seriesType: s.seriesType,
            setChartValues: s.setChartValues
        }),
        shallow
    );

    const handleToggleVolume = useCallback((showVolume) => setChartValues({ showVolume }), [setChartValues]);

    const {
        allowVolumeToggle = true,
        chartDimensions = { height: 350, width: 1000 },
        hideVolumeBadge = false,
        titleSectionSizes = { logo: 55, title: 32 }
    } = useAppWindowSizeVariable({
        sm: { chartDimensions: { height: 350, width: 670 }, hideVolumeBadge: true },
        md: { chartDimensions: { height: 350, width: 670 }, hideVolumeBadge: true },
        lg: { chartDimensions: { height: 350, width: 1000 } },
        tablet: {
            allowVolumeToggle: false,
            chartDimensions: { height: 320, width: 560 },
            hideVolumeBadge: true,
            titleSectionSizes: { logo: 40, title: 27 }
        }
    }) || {};

    useEffect(() => handleToggleVolume(showVolume), [handleToggleVolume, showVolume]);

    useEffect(() => {
        setStreamingChartData([]);
    }, [symbol]);

    useEffect(() => {
        setIsLoading(true);
        if (defaultRange) {
            setRange(defaultRange);
            xdispatch(XstreamUpdateChartFrequencyInMinutesAction(ChartMinuteOffsetsByRange[defaultRange]));
        }
        dispatch(GetSecurityMetadataAction(symbol));
        dispatch(GetSecurityQuoteAction(symbol));
        dispatch(GetSecurityCompanyInfoAction(symbol));
        if (assetClass.family === 'equities') dispatch(GetUpcomingEarningsAction(symbol));
    }, [assetClass.family, defaultRange, dispatch, symbol, xdispatch]);

    useEffect(() => {
        if (marketTimeSegment === 'open') {
            setPrevMarketHours(true);
            setIsLoading(!chart?.data?.length);
            const fetchChartDataAsync = async () => {
                dispatch(GetSecurityQuoteAction(symbol, true, true));
                dispatch(GetSecuritySymbolChart(symbol, range, 'hi', true));
            };
            fetchChartDataAsync();
        } else if (prevMarketHours && marketTimeSegment === ('open' as MarketTimeSegment)) {
            setPrevMarketHours(false);
        }
    }, [chart?.data?.length, dispatch, marketTimeSegment, prevMarketHours, range, symbol]);

    useEffect(() => {
        setIsLoading(true);
        // Is mounted now
        mountedRef.current = true;
        dispatch(GetSecuritySymbolChart(symbol, range, 'hi', true));
        if (assetClass === MutualFundAssetClass && seriesType !== 'line') setChartValues({ seriesType: 'line' });
        // Also in "useDidMount" we can use this cleanup function because it is just a wrapper around "useEffect"
        return () => {
            mountedRef.current = false;
        };
    }, [assetClass, dispatch, range, seriesType, setChartValues, symbol]);

    // TODO -- Please replace Chart with SecurityChartWrapper / similar
    useEffect(() => {
        setIsLoading(true);
        if (chart?.data?.length && !apiQuote?.pristine) {
            let orderedData = [...chart.data];

            orderedData = orderedData
                .sort((a, b) => Date.parse(QuoteAttribute.getTime(b)) - Date.parse(QuoteAttribute.getTime(a)))
                .filter((d) => isUTCTimestamp(QuoteAttribute.getTime(d)) && QuoteAttribute.getPrice(d));
            if (orderedData.length) {
                let newChartData = orderedData.map((d) => ({ time: getUnixTime(new Date(QuoteAttribute.getTime(d))), value: QuoteAttribute.getPrice(d), ...d }));
                if (rangeIsOneDay && !showAllData && IsMarketOpen(newChartData[0].time * 1000, true)) {
                    newChartData = newChartData.filter((d) => IsMarketOpen(d.time * 1000, true));
                }
                if (rangeIsOneDay && newChartData?.length) {
                    if (newChartData[newChartData.length - 1] && apiQuote?.data?.previousClose) {
                        newChartData[newChartData.length - 1].value = apiQuote?.data?.previousClose;
                    }
                }
                setIsLoading(false);
                const unique = UniqueBy(newChartData, (item: SecurityChartData & { time: number }) => item.time);
                setChartData(unique);
            }
        } else if (!chart?.data?.length && chart?.pristine) {
            setIsLoading(true);
        } else if (chart?.data && !chart?.data?.length && (chart?.error || !chart?.loading) && !chart?.pristine) {
            setChartData([]);
            setIsLoading(false);
        }
    }, [apiQuote, chart, range, rangeIsOneDay, showAllData, symbol, seriesType]);

    useEffect(() => {
        const orderedData =
            streamingData
                ?.sort((a, b) => Date.parse(QuoteAttribute.getTime(b)) - Date.parse(QuoteAttribute.getTime(a)))
                .filter((d) => isUTCTimestamp(QuoteAttribute.getTime(d)) && QuoteAttribute.getPrice(d)) || [];
        if (orderedData.length) {
            const newChartData = UniqueBy(
                orderedData.map((d) => ({ time: getUnixTime(new Date(QuoteAttribute.getTime(d))), value: QuoteAttribute.getPrice(d), ...d })),
                (item: SecurityChartData & { time: number }) => item.time
            );
            if (!chartData.length && rangeIsOneDay && !chart?.data?.length) setChartData(newChartData);
            setStreamingChartData(newChartData);
        }
    }, [chart?.data?.length, chartData.length, rangeIsOneDay, streamingData]);

    // TODO -- useMemo
    const currentPoint = streamingChartData[streamingChartData?.length - 1] || chartData[chartData.length - 1];

    const currentValue = currentPoint?.value || xStreamQuote?.price;
    const startingValue = rangeIsOneDay ? apiQuote?.data?.previousClose || chartData[0]?.value : chartData[0]?.value;
    const candleScrubStartingValue = chartData[0]?.value || chartData[0]?.latestPrice || chartData[0]?.open || chartData[0]?.close || 0;
    const valueChange = currentValue && startingValue ? (currentValue - startingValue === 0 ? 0.0 : currentValue - startingValue) : 0;

    const handleCrosshairUpdate = useCallback(
        (data?: CrosshairUpdateValueWithChange, isScrubbing?: boolean) => {
            setIsScrubbing(isScrubbing);

            if (!isScrubbing) {
                setSecurityValuesOverride(false);
                setShowAdditionalStats(false);
                setSecurityValues({ ...xStreamQuote });
                setStartingValue(startingValue);
            } else {
                setSecurityValuesOverride(true);

                if (['area', 'line'].includes(seriesType)) {
                    const lineValues = getLineScrubValues({ startingValue, value: data?.value });
                    setShowAdditionalStats(false);
                    setSecurityValues({ ...lineValues, price: lineValues.value, change: lineValues.changeValue, changePercent: lineValues.changePercentValue });
                } else {
                    const bar = data as ohlcvBar;
                    const candleValues = getCandleScrubValues({ bar, startingValue: candleScrubStartingValue });
                    setShowAdditionalStats(true);
                    setSecurityValues({ ...candleValues, price: candleValues.value, change: candleValues.changeValue, changePercent: candleValues.changePercentValue });
                }
            }
        },
        [xStreamQuote, startingValue, candleScrubStartingValue, seriesType]
    );

    const updateRange = useCallback(
        (newRange: ChartRange) => {
            if (newRange !== range) setIsLoading(true);
            setRange(newRange);
            xdispatch(XstreamUpdateChartFrequencyInMinutesAction(ChartMinuteOffsetsByRange[newRange]));
        },
        [range, xdispatch]
    );

    const title = useMemo(() => {
        if (!companyInfo) return null;
        const intendedName = companyInfo?.data?.securityName;
        const fallbackName = metadata?.data?.fullDescription;
        const effectiveName = intendedName || fallbackName;
        return effectiveName;
    }, [companyInfo, metadata?.data?.fullDescription]);

    useEffect(() => {
        if (chart && !chart?.pristine && isInitialLoad) setIsInitialLoad(false);
    }, [chartData.length, isInitialLoad, chart]);

    // TODO -- useMemo
    const showAdvancedChart = !hideAdvanced && GetVariant()?.showAdvancedChart;
    const { permittedForAlerts } = useTier();
    const showAlerts = !hideAlerts && permittedForAlerts;

    const TitleLine = useMemo(() => {
        if (header) return header;
        if (companyInfo?.pristine || companyInfo?.loading) {
            return (
                <Flex row align='center' style={{ height: 42 }}>
                    <Skeleton animation='wave' style={{ marginLeft: 10, height: 28, width: 250 }} />
                </Flex>
            );
        }
        return (
            <Flex row>
                <span style={{ fontSize: titleSectionSizes.title, fontWeight: 500, display: 'inline-block' }}>{title || '-----'}</span>
            </Flex>
        );
    }, [header, companyInfo?.pristine, companyInfo?.loading, titleSectionSizes.title, title]);

    const segmentScale = useMemo(() => {
        switch (range) {
            case '24h':
            case '1w':
            case '5d':
                return 'day';
            case '1d':
                return assetClass.family === 'equities' ? 'equities-day' : 'day';
            case '1m':
                return 'week';
            case '3m':
            case '6m':
            case '1y':
                return 'month';
            case '5y':
            default:
                return 'year';
        }
    }, [assetClass.family, range]);

    const showVolumeBadge =
        !!xStreamQuote?.volume && // First make sure there is a volume to show
        (seriesType !== 'candle' || // non-candlestick (line) can always show it
            !showAdditionalStats || // Or it can always be shown when scrubbing stats are not showing
            !hideVolumeBadge); // Or if it's not configured to be hidden

    const { primaryColor } = useAssetClassColors(assetClass, GetDeviceThemeVariant());

    const timezone = (assetClass.family === 'futures' ? SnexChartTimezone.cdt : SnexChartTimezone.edt) as SnexChartTimezoneType;

    // Don't show streaming data for any range besides 1D/24H
    const dataForRange = rangeIsOneDay ? [...chartData, ...streamingChartData] : chartData;

    // Sometimes streaming data is stored before the rest API returns
    // Only generate series data if the rest request has completed
    const multiSeriesData = useMemo(
        () => (chart?.pristine || chart?.loading ? undefined : (dataForRange as SnexChartPoint[])),
        [chart?.loading, chart?.pristine, chartData, streamingChartData]
    );

    const multiSeries = useMemo(
        () =>
            getMultiSeriesConfig({
                colors,
                data: multiSeriesData,
                formatPrice: formatValue,
                id: symbol,
                ...(accentColor ? { lineColor: accentColor, lineColorLighter: accentColorLighter } : {}),
                segmentScale,
                seriesType,
                showVolume,
                timezone,
                withHighLowMarkers: true
            }),
        [accentColor, accentColorLighter, colors, formatValue, multiSeriesData, segmentScale, seriesType, showVolume, symbol, timezone]
    );

    useEffect(() => {
        if (!chartData?.length && !streamingData?.length && !chart?.loading && !chart?.pristine) handleCrosshairUpdate(null);
    }, [xStreamQuote, range, chartData?.length, chart, streamingData?.length, handleCrosshairUpdate]);

    const expectedNumberOfPoints = getVisibleLogicalRangeOffset(multiSeriesData?.length);

    const logicalRangeOverride =
        assetClass.family === 'equities' &&
        range === '1d' &&
        // This override exists to make the chart appear as if it's moving right throughout the day
        // It is only relevant when the market is open
        // For premarket this app shows yesterday's chart so that segment is excluded
        ['open', 'postmarket'].includes(marketTimeSegment)
            ? expectedNumberOfPoints
            // When undefined, the chart will figure out the logical range on its own to display all the data
            : undefined;

    // TODO --- Abstract the JSX... Starting to get out of hand - Roman C.
    return (
        <SecurityChartContext.Provider value={{ workingOrdersInRange, setWorkingOrdersInRange }}>
            <Flex column key={`${appWindowSize}`} style={{ marginBottom: 20 }}>
                <Flex row justify='space-between' style={{ width: '100%' }}>
                    <CircularLogo
                        altText={title}
                        borderColor={assetClass.type === 'mutual-fund' ? null : primaryColor}
                        size={titleSectionSizes.logo}
                        style={{ marginTop: 4 }}
                        symbol={symbol}
                    />
                    <Flex column style={{ marginLeft: 20, flex: 1, width: 'inherit', minWidth: 0 }}>
                        <Flex row align='center' justify='space-between' style={{ width: '100%' }}>
                            {TitleLine}
                            <Flex row align='center' justify='flex-end' style={{ marginLeft: 20 }}>
                                {earningsDate && <EarningsDateTooltip symbol={symbol} />}
                                {showAlerts && (
                                    <GoldWrapper wrap={!product}>
                                        <div style={{ marginRight: 20 }}>
                                            <Tooltip title={`Create alert for ${symbol}`}>
                                                <div id='tour-p01-alerts-icon'>
                                                    {/* Revert to PremiumContentClickable upon Alerts working */}
                                                    <div
                                                        onClick={() => {
                                                            setIsAlertModalOpen(true);
                                                            Sentry.captureMessage('Alerts Clicked', 'info');
                                                        }}
                                                    >
                                                        <IconButton size='small'>
                                                            <Notifications />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </GoldWrapper>
                                )}
                                <div id='tour-03-add-to-watchlist'>
                                    <AddToListIcon securityId={symbol} onClick={() => setAddToListOpen(true)} />
                                </div>
                            </Flex>
                        </Flex>
                        <div className='security-values-wrapper'>
                            <SecurityValues
                                symbol={symbol}
                                accentColor={accentColor}
                                formatValue={formatValue}
                                hideChangeBar={hideChangeBar}
                                range={range}
                                delayed={delayedData}
                            />
                            {showVolumeBadge && (
                                <VolumeBadge loading={apiQuote?.pristine} volume={FormatNumber.toCommas(xStreamQuote?.volume)} volumeText={text.stats.volume} />
                            )}
                        </div>
                        {!hideExtendedHours && <ExtendedHoursSnapshot isLoading={isLoading} symbol={symbol} />}
                        <LastQuoteUpdateDisplay
                            debounceMs={50 * 1000}
                            prefix='Price as of'
                            qsi={symbol}
                            style={{ marginTop: 10, color: 'rgba(150,150,150,0.8)', fontSize: 11 }}
                        />
                    </Flex>
                </Flex>
                <div style={{ position: 'relative', minHeight: chartDimensions.height }}>
                    <ErrorBoundary>
                        {(isInitialLoad || showSkeleton) && (
                            <div style={{ position: 'absolute' }}>
                                <SkeletonChart containerId={seriesType + `-${appWindowSize}-skeleton`} height={chartDimensions.height} width={chartDimensions.width} />
                            </div>
                        )}
                        {showSkeleton || (
                            <div style={{ visibility: isInitialLoad ? 'hidden' : 'visible' }}>
                                <MultiSeriesChart
                                    canScale={seriesType === 'candle'}
                                    canToggleSeries={true}
                                    height={chartDimensions.height}
                                    isLoading={isLoading}
                                    logicalRangeOverride={logicalRangeOverride}
                                    multiSeries={multiSeries}
                                    onCrosshairUpdate={handleCrosshairUpdate}
                                    range={range}
                                    showVolume={showVolume}
                                    showWorkingOrders
                                    width={chartDimensions.width}
                                />
                            </div>
                        )}
                    </ErrorBoundary>
                </div>

                <Flex justify='space-between' style={{ marginTop: 10 }}>
                    <Flex row align='flex-end' justify='flex-start'>
                        <RangeSelector
                            excludeRanges={hiddenRanges || excludeChartRanges()}
                            negativeColorOverride={accentColor}
                            positiveColorOverride={accentColor}
                            selectedRange={range}
                            setRange={updateRange}
                            valueChange={
                                rangeIsOneDay ? QuoteAttribute.getChange(xStreamQuote, true) || valueChange : valueChange || QuoteAttribute.getChange(xStreamQuote, true)
                            }
                        />
                        {!allowVolumeToggle && !hideCandlestick && showWorkingOrdersCheckbox && seriesType === 'candle' ? (
                            <>
                                <CandleLineToggleControls
                                    allowVolumeToggle={allowVolumeToggle}
                                    onDisplayTypeChange={(seriesType) => setChartValues({ seriesType })}
                                    onToggleVolume={(showVolume) => setChartValues({ showVolume })}
                                />

                                <div style={{ position: 'relative' }}>
                                    <IconButton onClick={handleToggleMenu}>
                                        <MoreVert />
                                    </IconButton>
                                    <SnexPopover
                                        open={toggleMenuOpen}
                                        top={0}
                                        left={0}
                                        onClose={() => setToggleMenuOpen(!toggleMenuOpen)}
                                        paperStyles={{ width: 'auto' }}
                                    >
                                        <Card className='chart-context-menu'>
                                            <div className='chart-toggle-checkbox' onClick={() => setShowVolume(!showVolume)}>
                                                <Typography variant='subtitle2'>{text.showVolume}</Typography>
                                                <Checkbox checked={showVolume} size='small' style={{ padding: 0, marginLeft: 5 }} />
                                            </div>
                                            <div className='chart-toggle-checkbox' onClick={() => setShowWorkingOrders(!showWorkingOrders)}>
                                                <Typography variant='subtitle2'>{allText.misc.showWorkingOrders}</Typography>
                                                <Checkbox checked={showWorkingOrders} size='small' style={{ padding: 0, marginLeft: 5 }} />
                                            </div>
                                        </Card>
                                    </SnexPopover>
                                </div>
                            </>
                        ) : (
                            <>
                                {!hideCandlestick && (
                                    <CandleLineToggleControls
                                        allowVolumeToggle={allowVolumeToggle}
                                        onDisplayTypeChange={(seriesType) => setChartValues({ seriesType })}
                                        onToggleVolume={(showVolume) => setChartValues({ showVolume })}
                                    />
                                )}
                                {showWorkingOrdersCheckbox && (
                                    <div className='chart-toggle-checkbox' onClick={() => setShowWorkingOrders(!showWorkingOrders)}>
                                        <Typography variant='subtitle2'>{allText.misc.showWorkingOrders}</Typography>
                                        <Checkbox checked={showWorkingOrders} size='small' style={{ padding: 0, marginLeft: 5 }} />
                                    </div>
                                )}
                            </>
                        )}
                    </Flex>
                    {showAdvancedChart && (
                        <Flex align='center' id='tour-02-advanced-chart-button' justify='flex-end' style={{ flex: 0, paddingTop: 5, whiteSpace: 'nowrap' }}>
                            <ArrowLink
                                noAnimate
                                className={assetClass.family}
                                eventTag='Advanced Chart'
                                route={Routes.advancedChart(symbol)}
                                icon={<AspectRatio style={{ fill: primaryColor, fontSize: 14 }} />}
                                textStyle={primaryColor ? { color: primaryColor } : undefined}
                            >
                                {advancedChartText}
                            </ArrowLink>
                        </Flex>
                    )}
                </Flex>
                <ActionModal closerStyle='done-button' isOpen={addToListOpen} label={text.addToList} toggleModal={() => setAddToListOpen(false)}>
                    <AddToListModal symbol={symbol} />
                </ActionModal>
                <AlertsModal isOpen={isAlertModalOpen} symbol={symbol} toggleModal={setIsAlertModalOpen} />
            </Flex>
        </SecurityChartContext.Provider>
    );
}, TelemetryCategories.chart);
